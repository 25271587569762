import { faArrowLeft, faArrowRight, faDollarSign, faHome, faList, faRocket, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ApiClient from "../lib/client";
import withAuth from "./data/withAuth";
import withCommunity from "./data/withCommunity";
import Alert from "./ui/alert";
import Button from "./ui/button";
import CheckBox from "./ui/checkbox";
import Modal from "./ui/modal";
import TextField from "./ui/textfield";
import FileDropZone from './ui/filedropzone';
import { Transition } from "@headlessui/react";
import getEnv from "../lib/getEnv";
import CircleLoader from "./ui/circleLoader";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import RadioGroup from "./ui/radioGroup";
import classnames from "tailwindcss-classnames";

const communityTypes = [
  {
    id: 'public_community',
    name: 'Public Community',
    description: 'Admins and pass holders can post; visible to the public'
  },
  {
    id: 'gated_community',
    name: 'Gated Community',
    description: 'Admins and pass holders can create and view posts; private to the public'
  },
  {
    id: 'gated_feed',
    name: 'Gated Feed',
    description: 'Only admins can post; only visible to pass holders'
  },
];

const generateUID = (length, chars = UID_CHARS) => {
  let str = '';
  for (let i = 0; i < length; i += 1) {
    const choice = Math.floor(Math.random() * chars.length);
    str = str + chars[choice];
  }
  return str;
};

function CommunitySetupModal(props) {
  const {
    user,
    open,
    onClose,
    community,
    mutateCommunity,
    onFinish = (() => {}),
  } = props;

  const [pane, setPane] = useState('startToken');

  const api = new ApiClient();
  const env = getEnv();
  let baseUrl = 'https://api.withcomet.com/comet';
  if (env === 'dev') {
    baseUrl = 'https://api.withcomet.dev/comet';
  } else if (env === 'local') {
    baseUrl = 'http://localhost:9000/comet';
  }
  const rawAPI = new ApiClient(baseUrl);

  const [initializing, setInitializing] = useState(false);

  const [waitlistCapped, setWaitlistCapped] = useState(false);
  const [waitlistMax, setWaitlistMax] = useState(1000);
  const [waitlistPrivate, setWaitlistPrivate] = useState(false);

  const [waitlistAllowCreatePosts, setWaitlistAllowCreatePosts] = useState(false);
  const [waitlistAllowCommentPosts, setWaitlistAllowCommentPosts] = useState(true);
  const [waitlistAllowUpvotePosts, setWaitlistAllowUpvotePosts] = useState(true);
  const [waitlistAllowCastVotes, setWaitlistAllowCastVotes] = useState(true);

  const [nonWaitlistAllowViewPosts, setNonWaitlistAllowViewPosts] = useState(true);
  const [nonWaitlistAllowStarPosts, setNonWaitlistAllowStarPosts] = useState(false);
  const [nonWaitlistAllowCommentPosts, setNonWaitlistAllowCommentPosts] = useState(false);

  const [waitlistOut, setWaitlistOut] = useState(false);
  const [waitlistError, setWaitlistError] = useState(null);

  const [tokenName, setTokenName] = useState('');
  const [tokenDescription, setTokenDescription] = useState('');
  const [maxSupply, setMaxSupply] = useState(1000);
  const [pricingModel, setPricingModel] = useState('free');
  const [pricePerPass, setPricePerPass] = useState(10);
  const [nftImage, setNftImage] = useState(null);
  const [launchOut, setLaunchOut] = useState(false);
  const [launchText, setLaunchText] = useState('');
  const [communityType, setCommunityType] = useState('public_community');
  const [mintPrivate, setMintPrivate] = useState(false);
  const [minContribution, setMinContribution] = useState(1);
  const [infiniteSupply, setInfiniteSupply] = useState(false);

  const initialize = async () => {
    setInitializing(true);
    // setPane('startToken');
    // console.log('start');
    // first, check where we are in the launch process
    if (community && community.tokens?.[0]) {
      // console.log('tokens exist');
      if (Object.keys(community.access.rules).length < 2) {
        // console.log('adding token permissions');
        await addTokenPermissions(community.tokens?.[0].id); // non-async
      }

      if (!community.tokens?.[0].metadata?.deployed) {
        // we are in the process of deploying this token
        // skip to launchLoader
        setPane('launchLoader');
        setLaunchOut(true);
        setLaunchText('Launching NFT. This may take a minute or two.')
        // console.log('wait for deployment');
        await waitForDeployment()
      } else if (
        community.tokens?.[0].metadata?.deployed
      ) {
        setPane('launchLoader');
        setLaunchOut(true);
        setLaunchText('Updating community permissions...');
        // console.log('updating perms');
      }
    }
    setInitializing(false);
  }

  useEffect(() => {
    initialize();
  }, [community.tokens?.[0]]);

  const createWaitlistAndUpdateAccess = async () => {
    try {
      setWaitlistOut(true);

      const whitelist = await api.post(
        `/community/${community.id}/whitelist`,
        {
          maxUsers: waitlistCapped ? parseInt(waitlistMax) : -1,
          isPrivate: waitlistPrivate,
          name: 'Prelaunch Waitlist',
        },
      );

      const whitelistRoles = [
        'viewPosts',
        'upvotePosts',
        'commentPosts',
      ]; // base roles
      if (waitlistAllowCastVotes) {
        whitelistRoles.push('castVotes');
      }
      if (waitlistAllowCommentPosts) {
        whitelistRoles.push('commentPosts');
      }
      if (waitlistAllowUpvotePosts) {
        whitelistRoles.push('upvotePosts');
      }
      if (waitlistAllowCreatePosts) {
        whitelistRoles.push('createPosts');
      }

      const publicRoles = [];
      if (nonWaitlistAllowCommentPosts) {
        publicRoles.push('commentPosts');
      }
      if (nonWaitlistAllowStarPosts) {
        publicRoles.push('upvotePosts');
      }
      if (nonWaitlistAllowViewPosts) {
        publicRoles.push('viewPosts');
      }

      // update access settings on the community itself
      await api.put(
        `/community/${community.id}/access`,
        {
          rules: [
            {
              id: 'prelaunch_whitelist',
              type: 'in_whitelist',
              config: {
                whitelistId: whitelist.id,
              },
              applyRoles: whitelistRoles,
            },
            {
              id: 'initial_public_roles',
              type: 'allow_all',
              applyRoles: publicRoles,
            },
          ],
        },
      );
      await mutateCommunity();
      setWaitlistOut(false);
    } catch (e) {
      console.error(e);
      setWaitlistError(e);
      setWaitlistOut(false);
    }
  }

  const nameToSymbol = (name) => {
    const words = name.match(/\S+/g);
    if (words.length === 1) {
    return words[0].substring(0, 3).toUpperCase().replace(/\d+/, 'X');
    } else if (words.length === 2) {
      return (words[0].substring(0, 2) + words[1][0]).toUpperCase().replace(/\d+/, 'X');
    } else {
      return (words[0][0] + words[1][0] + words[2][0]).toUpperCase().replace(/\d+/, 'X');
    }
  }

  const validateName = (name) => {
    // cant be less than 3 letters
    if (name.length < 3) {
      return false;
    } else if (name.length > 20) {
      return false;
    }

    return true;
  }

  const validateDescription = (desc) => {
    if (desc.length < 3) {
      return false;
    } else if (desc.length > 80) {
      return false;
    }
    return true;
  }

  const addTokenPermissions = async (tokenId) => {
    const createRoles = [
      'commentPosts',
      'createPosts',
      'castVotes',
    ];

    const viewRoles = [
      'upvotePosts',
      'viewPosts',
    ];

    let tokenRoles = [];
    let publicRoles = [];

    if (communityType === 'public_community') {
      tokenRoles = [...viewRoles, ...createRoles];
      publicRoles = [...viewRoles];
    } else if (communityType === 'gated_community') {
      tokenRoles = [...viewRoles, ...createRoles];
    } else if (communityType === 'gated_feed') {
      tokenRoles = [...viewRoles];
    }

    // const whitelistRoles = [
    //   'viewPosts',
    //   'upvotePosts',
    //   'commentPosts',
    // ];
    // if (waitlistAllowCastVotes) {
    //   whitelistRoles.push('castVotes');
    // }
    // if (waitlistAllowCommentPosts) {
    //   whitelistRoles.push('commentPosts');
    // }
    // if (waitlistAllowUpvotePosts) {
    //   whitelistRoles.push('upvotePosts');
    // }
    // if (waitlistAllowCreatePosts) {
    //   whitelistRoles.push('createPosts');
    // }

    // const publicRoles = [];
    // if (nonWaitlistAllowCommentPosts) {
    //   publicRoles.push('commentPosts');
    // }
    // if (nonWaitlistAllowStarPosts) {
    //   publicRoles.push('upvotePosts');
    // }
    // if (nonWaitlistAllowViewPosts) {
    //   publicRoles.push('viewPosts');
    // }

    await api.put(
      `/community/${community.id}/access`,
      {
        rules: [
          {
            id: 'first_membership_pass',
            type: 'min_tokens',
            config: {
              tokenId,
              threshold: 1,
            },
            applyRoles: tokenRoles,
          },
          {
            id: 'initial_public_roles',
            type: 'allow_all',
            applyRoles: publicRoles,
          },
        ],
      },
    );
  }

  const waitForDeployment = async () => {
    await mutateCommunity();

    let newc = community;

    while (!newc.tokens?.[0]?.metadata?.deployed) {
      // console.log(newc,'a')
      await new Promise((resolve) => { setTimeout(resolve, 2500); });
      newc = await mutateCommunity();
    }
  }

  const uploadFileLaunchTokenAndUpdateAccess = async () => {
    try {
      setLaunchOut(true);
      setLaunchText('Uploading assets...');

      // create OTT string
      const { string: ottString } = await api.post('/auth/ott', { expireSeconds: 60, reason: 'upload_file' });

      // upload NFT image to server
      const data = new FormData();
      data.append('file', nftImage);

      const { key } = await rawAPI.post(
        '/uploads',
        data,
        false,
        {
          'X-Comet-OTT': ottString,
        },
      );

      let mintPassword = '';
      if (mintPrivate) {
        mintPassword = generateUID(10, 'QWERTYUIOPASDLKFJHGZXCNMBV1234567890');
      }

      let price = 0;
      if (pricingModel === 'free') {
        price = 0;
      } else if (pricingModel === 'pay_once') {
        price = Math.ceil(pricePerPass * 100) / 100;
      } else if (pricingModel === 'pay_once_variable') {
        price = Math.ceil(minContribution * 100) / 100;
      }

      setLaunchText('Launching NFT. This may take a minute or two.');
      const params = {
        name: tokenName,
        symbol: nameToSymbol(tokenName),
        type: 'milky_way_nft',
        chainId: env === 'prod' ? 101 : 103,
        chainType: 'solana',
        subtype: 'numbered_pass',
        config: {
          backgroundUpload: key,
          description: tokenDescription,
          pricingModel,
          price,
          maxSupply: infiniteSupply ? -1 : maxSupply,
          infiniteSupply,
          mintPassword,
        },
        launch: true,
        // launchQueue: 'AniQueue',
        communityId: community.id,
      };

      const { id: tokenId } = await api.post(
        '/token/definition',
        params,
      );

      await addTokenPermissions(tokenId);
      await waitForDeployment();
    } catch (e) {
      setLaunchOut(false);
    }
  }

  const panes = {

    start: {
      title: 'Add people to your project',
      content: (
        <div className="flex flex-col gap-3">
          <p className=" text-slate-500">
          Congrats on starting your project on Comet! The next step: bring in your contributors, fans, and community members.
          </p>
          <button
            // disabled
            variant="secondary"
            className="w-full rounded-xl p-3 transition-opacity duration-100 disabled:opacity-60 inline-flex items-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 text-slate-700 bg-slate-200 hover:bg-slate-200"
            onClick={() => { setPane('startToken'); }}
          >
            <div className="w-full flex flex-row items-start gap-2">
              <div className="w-14 flex-shrink-0">
                <FontAwesomeIcon icon={faTicket} size="2x" />
              </div>
              <div className="grow text-left">
                <p className="text-md font-bold">Launch Membership Pass</p>
                <p className="text-sm font-medium text-slate-500 leading-5">
                  Launch an NFT membership pass that anyone can purchase to get access to your
                  community. You'll get the proceeds.
                </p>
                <p className="text-slate-900"><b>COMING SOON</b></p>
              </div>
            </div>
          </button>
          <button
            variant="secondary"
            className="w-full rounded-xl p-3 transition-opacity duration-100 disabled:opacity-60 inline-flex items-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 text-slate-700 bg-slate-200 hover:bg-slate-300"
            onClick={() => { setPane('startWaitlist'); }}
          >
            <div className="w-full flex flex-row items-start gap-2">
              <div className="w-14 flex-shrink-0">
                <FontAwesomeIcon icon={faList} size="2x" className="mt-1" />
              </div>
              <div className="grow text-left">
                <p className="text-md font-bold">Launch Waitlist</p>
                <p className="text-sm font-medium text-slate-500 leading-5">
                  Don't want to sell an NFT yet? Launch a waitlist and host your community
                  on Comet. You can always launch the NFT later.
                </p>
              </div>
            </div>
          </button>
        </div>
      )
    },

    startWaitlist: {
      title: 'Launch Waitlist',
      content: (
        <div className="flex flex-col gap-3">
          <div className="my-2 flex flex-col gap-3">
            <CheckBox
              name="waitlistCapped"
              caption="Waitlist size is capped"
              value={waitlistCapped}
              onChange={(e) => { setWaitlistCapped(e.target.checked); }}
              disabled={waitlistOut}
            />
            <TextField
              label="Max waitlist spots"
              disabled={!waitlistCapped || waitlistOut}
              type="number"
              value={waitlistMax}
              size="lg"
              onChange={(e) => { setWaitlistMax(e.target.value); }}
            />
          </div>

          <div className="my-2 flex flex-col gap-3">
            <CheckBox
              name="waitlistPrivate"
              caption="Requires private link to join"
              value={waitlistPrivate}
              onChange={(e) => { setWaitlistPrivate(e.target.checked); }}
              disabled={waitlistOut}
            />
          </div>

          <hr />

          <div className="my-2 flex flex-col gap-3">
            <p className="text-base text-slate-500">
              <b>Waitlist members</b> will have the following permissions in the community:
            </p>
            <CheckBox
              name="waitlistAllowCreatePosts"
              caption="Create posts"
              value={waitlistAllowCreatePosts}
              onChange={(e) => { setWaitlistAllowCreatePosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowUpvotePosts"
              caption="Star posts"
              value={waitlistAllowUpvotePosts}
              onChange={(e) => { setWaitlistAllowUpvotePosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowCommentPosts"
              caption="Comment on posts"
              value={waitlistAllowCommentPosts}
              onChange={(e) => { setWaitlistAllowCommentPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowCastVotes"
              caption="Cast votes in polls"
              value={waitlistAllowCastVotes}
              onChange={(e) => { setWaitlistAllowCastVotes(e.target.checked); }}
              disabled={waitlistOut}
            />
          </div>

          <div className="my-2 flex flex-col gap-3">
            <p className="text-base text-slate-500">
              <b>The public</b> will have the following permissions:
            </p>
            <CheckBox
              name="nonWaitlistAllowViewPosts"
              caption="View posts"
              value={nonWaitlistAllowViewPosts}
              onChange={(e) => { setNonWaitlistAllowViewPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            {/* <CheckBox
              name="nonWaitlistAllowStarPosts"
              caption="Star posts"
              value={nonWaitlistAllowStarPosts}
              onChange={(e) => { setNonWaitlistAllowStarPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="nonWaitlistAllowCommentPosts"
              caption="Comment on posts"
              value={nonWaitlistAllowCommentPosts}
              onChange={(e) => { setNonWaitlistAllowCommentPosts(e.target.checked); }}
              disabled={waitlistOut}
            /> */}
          </div>

          {
            waitlistError && (
              <Alert>
                {waitlistError.response?.data?.message || 'There was an error launching the waitlist'}
              </Alert>
            )
          }

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('start') }}
              disabled={waitlistOut}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              onClick={createWaitlistAndUpdateAccess}
            >
              Finish
            </Button>
          </div>
        </div>
      )
    },

    startToken: {
      title: '1. Launch your NFT',
      content: (
        <div className="flex flex-col gap-3">

          <TextField
            label="Name of membership pass"
            placeholder={`The OG Circle`}
            value={tokenName}
            size="lg"
            autoFocus
            onChange={(e) => { setTokenName(e.target.value); }}
            description={
              <span className={classnames({
                'text-red-500': tokenName.length > 20
              })}>{20 - tokenName.length} characters left</span>
            }
          />

          <TextField
            label="Membership description"
            multiline
            placeholder={`Get exclusive access to the ${community.name} community!`}
            value={tokenDescription}
            size="md"
            minRows={3}
            maxRows={7}
            onChange={(e) => { setTokenDescription(e.target.value); }}
            description={
              <span className={classnames({
                'text-red-500': tokenDescription.length > 80
              })}>{80 - tokenDescription.length} characters left</span>
            }
          />

          <CheckBox
            name="mintPrivate"
            caption="Only people with private link can mint"
            value={mintPrivate}
            onChange={(e) => { setMintPrivate(e.target.checked); }}
            disabled={waitlistOut}
          />

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            {/* <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('start') }}
            >
              Back
            </Button> */}
            <div></div>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              onClick={() => { setPane('setPricingModel') }}
              disabled={
                tokenName.length < 3
                || tokenDescription.length < 1
                || !validateName(tokenName)
                || !validateDescription(tokenDescription)
              }
            >
              Next
            </Button>
          </div>
        </div>
      )
    },

    setPricingModel: {
      title: '2. Choose pricing model',
      content: (
        <div className="flex flex-col gap-3">

          <RadioGroup
            value={pricingModel}
            onChange={(v) => { setPricingModel(v); if (v === 'pay_once_variable') setInfiniteSupply(true); }}
            options={[
              {
                id: 'free',
                name: 'Free to claim',
                description: 'Works well for event RSVPs, interest groups, and user lists.'
              },
              {
                id: 'pay_once',
                name: 'Fixed price',
                description: 'Everyone pays the same fixed price up-front.'
              },
              {
                id: 'pay_once_variable',
                name: 'Name your price',
                description: 'Members can choose their price. Perfect for donations and fundraisers.',
              },
            ]}
          />

          {/* <TextField
            label="Name of membership pass"
            placeholder={`The OG Circle`}
            value={tokenName}
            size="lg"
            autoFocus
            onChange={(e) => { setTokenName(e.target.value); }}
          />

          <TextField
            label="Membership description"
            multiline
            placeholder={`Get exclusive access to the ${community.name} community!`}
            value={tokenDescription}
            size="md"
            minRows={3}
            maxRows={7}
            onChange={(e) => { setTokenDescription(e.target.value); }}
          /> */}

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('startToken') }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              onClick={() => { setPane('tokenSetPrice') }}
              disabled={!pricingModel}
            >
              Next
            </Button>
          </div>
        </div>
      )
    },

    tokenSetPrice: {
      title: pricingModel === 'pay_once_variable' ? '3. Set minimum contribution' : '3. Set supply',
      content: (
        <div className="flex flex-col gap-3">


          <CheckBox
            className="w-1/2"
            align="center"
            name="mintPrivate"
            caption="Infinite supply"
            value={infiniteSupply}
            onChange={(e) => { setInfiniteSupply(e.target.checked); }}
            disabled={waitlistOut}
          />

          <TextField
            label="Number of passes"
            value={maxSupply}
            type="number"
            size="lg"
            onChange={(e) => { setMaxSupply(e.target.value); }}
            min={1}
            autoFocus
            disabled={infiniteSupply}
            description={`You'll automatically receive 1 NFT, so there will be ${maxSupply - 1} left to mint.`}
          />

          {
            ['pay_once'].includes(pricingModel) && (
              <>
                <TextField
                  label="Price per membership pass"
                  value={pricePerPass}
                  type="number"
                  size="lg"
                  leftIcon={faDollarSign}
                  onChange={(e) => { setPricePerPass(e.target.value); }}
                  min={0}
                />

                <Alert variant="info" className="mt-2">
                  Comet charges a 5% flat fee on sales.{' '}
                  {
                    !infiniteSupply && (
                      <>
                        If all {maxSupply - 1} of your passes are
                        purchased, you will receive ${Math.floor((maxSupply - 1) * pricePerPass * 95) / 100}.
                      </>
                    )
                  }
                </Alert>

                {
                  !user.stripeSeller?.chargesEnabled && (
                    <Alert variant="error" className="mt-1">
                      <p>
                        Your account hasn't been set up to receive payments yet.
                        Go to <a href="/settings/payments" target="_blank" className="underline">Settings</a> to
                        get started.
                      </p>
                    </Alert>
                  )
                }
              </>
            )
          }

          {
            ['pay_once_variable'].includes(pricingModel) && (
              <>
                <TextField
                  label="Minimum contribution amount"
                  description="Members can contribute any amount greater than this"
                  value={minContribution}
                  type="number"
                  size="lg"
                  leftIcon={faDollarSign}
                  onChange={(e) => { setMinContribution(e.target.value); }}
                  min={0}
                />

                <Alert variant="info" className="mt-2">
                  Comet charges a 5% flat fee on sales.{' '}
                  {
                    !infiniteSupply && (
                      <>
                        If all {maxSupply - 1} of your passes are
                        purchased, you will receive ${Math.floor((maxSupply - 1) * pricePerPass * 95) / 100}.
                      </>
                    )
                  }
                </Alert>

                {
                  !user.stripeSeller?.chargesEnabled && (
                    <Alert variant="error" className="mt-1">
                      <p>
                        Your account hasn't been set up to receive payments yet.
                        Go to <a href="/settings/payments" target="_blank" className="underline">Settings</a> to
                        get started.
                      </p>
                    </Alert>
                  )
                }
              </>
            )
          }

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('setPricingModel') }}
              disabled={waitlistOut}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              onClick={() => { setPane('tokenSetPermissions') }}
              disabled={(
                maxSupply <= 1
                || pricePerPass <= 0
              )}
            >
              Next
            </Button>
          </div>
        </div>
      )
    },

    tokenSetPermissions: {
      title: '4. Set up community',
      content: (
        <div className="flex flex-col gap-3">

          <RadioGroup
            value={communityType}
            onChange={setCommunityType}
            options={communityTypes}
          />

          {/* <div className="my-2 flex flex-col gap-3">
            <p className="text-base text-slate-500">
              <b>Pass holders</b> will have the following permissions in the community:
            </p>
            <CheckBox
              name="waitlistAllowCreatePosts"
              caption="Create posts"
              value={waitlistAllowCreatePosts}
              onChange={(e) => { setWaitlistAllowCreatePosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowUpvotePosts"
              caption="Star posts"
              value={waitlistAllowUpvotePosts}
              onChange={(e) => { setWaitlistAllowUpvotePosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowCommentPosts"
              caption="Comment on posts"
              value={waitlistAllowCommentPosts}
              onChange={(e) => { setWaitlistAllowCommentPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="waitlistAllowCastVotes"
              caption="Cast votes in polls"
              value={waitlistAllowCastVotes}
              onChange={(e) => { setWaitlistAllowCastVotes(e.target.checked); }}
              disabled={waitlistOut}
            />
          </div>

          <div className="my-2 flex flex-col gap-3">
            <p className="text-base text-slate-500">
              <b>The public</b> will have the following permissions:
            </p>
            <CheckBox
              name="nonWaitlistAllowViewPosts"
              caption="View posts"
              value={nonWaitlistAllowViewPosts}
              onChange={(e) => { setNonWaitlistAllowViewPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="nonWaitlistAllowStarPosts"
              caption="Star posts"
              value={nonWaitlistAllowStarPosts}
              onChange={(e) => { setNonWaitlistAllowStarPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
            <CheckBox
              name="nonWaitlistAllowCommentPosts"
              caption="Comment on posts"
              value={nonWaitlistAllowCommentPosts}
              onChange={(e) => { setNonWaitlistAllowCommentPosts(e.target.checked); }}
              disabled={waitlistOut}
            />
          </div> */}

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('tokenSetPrice') }}
              disabled={waitlistOut}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              onClick={() => { setPane('tokenUploadImage') }}
            >
              Next
            </Button>
          </div>
        </div>
      )
    },

    tokenUploadImage: {
      title: '5. Upload artwork',
      content: (
        <div className="flex flex-col gap-3">

          <p className="font-medium text-md text-slate-700">
            Upload an image that'll be displayed on the NFT. Recommended size is 840x840.
          </p>

          <div className="flex flex-row items-center justify-center my-2">
            {
              !nftImage && (
                <FileDropZone
                  zoneWidth={250}
                  zoneHeight={250}
                  acceptTypes={{ 'image/png': [], 'image/jpg': [], 'image/jpeg': [] }}
                  value={nftImage ? [nftImage] : []}
                  onChange={(files) =>{ if (files && files.length) setNftImage(files[0]); }}
                />
              )
            }
            {
              nftImage && (
                <div className='w-full flex items-center justify-center'>
                  <div className='relative' style={{ width: 250, height: 250 }}>
                    <div className="relative h-full w-full border-slate-600 border-2 rounded-lg overflow-hidden">
                      <img
                        className='absolute h-full w-full object-fill'
                        src={nftImage ? URL.createObjectURL(nftImage) : undefined}
                      />
                    </div>
                    <div className='absolute top-0 right-0 -mr-1.5 -mt-1.5'>
                      <Button
                        className='w-5 h-5 rounded-full bg-slate-600 text-white'
                        leftIcon={faXmark}
                        onClick={() => { setNftImage(null) }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )
            }
          </div>

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('tokenSetPermissions') }}
              disabled={waitlistOut}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faArrowRight}
              loading={waitlistOut}
              disabled={!nftImage}
              onClick={() => { setPane('reviewNFT') }}
            >
              Next
            </Button>
          </div>
        </div>
      )
    },

    reviewNFT: {
      title: '6. Review',
      content: (
        <div className="flex flex-col gap-3">

          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-3 items-start">
              <p className="w-24">
                <b>Name</b>
              </p>
              <p className="grow">
                {tokenName}
              </p>
            </div>

            <div className="flex flex-row gap-3 items-start">
              <p className="w-24">
                <b>Description</b>
              </p>
              <p className="grow">
                {tokenDescription}
              </p>
            </div>

            <div className="flex flex-row gap-3 items-start">
              <p className="w-24">
                <b>Max supply</b>
              </p>
              <p className="grow">
                {maxSupply}
              </p>
            </div>

            {
              pricingModel === 'pay_once' && (
                <div className="flex flex-row gap-3 items-start">
                  <p className="w-24">
                    <b>Token price</b>
                  </p>
                  <p className="grow">
                    ${Math.ceil(pricePerPass * 100) / 100}
                  </p>
                </div>
              )
            }

            <div className="flex flex-row gap-3 items-start">
              <p className="w-24">
                <b>Project type</b>
              </p>
              <p className="grow">
                {communityTypes.filter(com => com.id === communityType)[0].name}
              </p>
            </div>

            <div className="flex flex-col gap-2 items-start">
              <p className="w-full">
                <b>NFT preview:</b>
              </p>
              <div className="grow self-center mb-2">
                <div className="relative rounded-2xl overflow-hidden" style={{ width: 252, height: 324 }}>
                  <div className="absolute" style={{ width: 252, height: 252 }}>
                    <img className="object-fill h-full w-full" src={nftImage ? URL.createObjectURL(nftImage) : undefined} />
                  </div>
                  <div className="absolute bg-black" style={{ height: 72, width: 252, top: 252 }}></div>
                  <p style={{ fontSize: 22, top: 262, left: 18 }}  className="absolute font-bold text-white">#0</p>
                  <p style={{ fontSize: 11, top: 290, left: 18 }}  className="absolute font-bold text-white">{tokenName}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-2 mt-2">
            <Button
              variant="secondary"
              leftIcon={faArrowLeft}
              onClick={() => { setPane('tokenUploadImage') }}
              disabled={waitlistOut}
            >
              Back
            </Button>
            <Button
              variant="primary"
              rightIcon={faRocket}
              loading={waitlistOut}
              onClick={() => { setPane('launchLoader'); uploadFileLaunchTokenAndUpdateAccess(); }}
            >
              Launch
            </Button>
          </div>
        </div>
      )
    },

    launchLoader: {
      title: '',
      content: (
        <div className="flex flex-col gap-3">
          <div className="w-full my-5 flex flex-col items-center justify-center">
            {
              launchOut && <CircleLoader size={70} />
            }
            {
              !launchOut && <FontAwesomeIcon className="text-slate-700" size={'5x'} icon={faCheckCircle} />
            }
            <p className="mt-5 font-medium text-slate-700 text-center">{launchText}</p>
          </div>
        </div>
      )
    }

  }

  if (user.inCreatorWhitelist) {
    return (
      <Modal
        width="base"
        open={open}
        closeable={false}
        title={panes[pane].title}
      >
        {panes[pane].content}
      </Modal>
    )
  } else {
    return (
      <Modal
        width="base"
        open={open}
        closeable={false}
        title={"Launch your project on Comet"}
      >
        <p className="mb-3 font-medium text-slate-500">
          Right now, we're only letting a select few creators launch projects on Comet.
        </p>
        <p className="mb-3 font-medium text-slate-500">
          We want to focus on building the perfect product before we release it to everyone.
        </p>
        <p className="mb-3 font-medium text-slate-500">
          In the meantime, <a href="https://airtable.com/shrXC02rIDSC2qGjM" target="_blank" className="text-blue-500 underline cursor-pointer">let us know</a> what you
          plan on building to reserve a spot in line!
        </p>
        <div className="text-center mt-5">
          <Button href="/home" variant="secondary" leftIcon={faHome}>Back home</Button>
        </div>
      </Modal>
    )
  }
}

export default withAuth(withCommunity(CommunitySetupModal), { authRequired: false });
