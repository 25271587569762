import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

export default function RadioGroupComet(props) {
  const {
    options: plans,
    value = plans[0].id,
    onChange = () => {},
  } = props;

  return (
    <RadioGroup value={value} onChange={(v) => { onChange(v); }}>
      {/* <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label> */}
      <div className="space-y-2">
        {
          plans.map((plan) => {
            return (
              <RadioGroup.Option
                key={plan.name}
                value={plan.id}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-slate-300'
                      : ''
                  }
                  ${
                    checked ? 'bg-slate-600 text-white' : 'bg-slate-100'
                  }
                    relative flex cursor-pointer rounded-2xl px-4 py-3 focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center pr-3">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium text-lg ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-slate-100' : 'text-gray-500'
                            }`}
                          >
                            <span>
                              {plan.description}
                            </span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked
                        ? (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )
                        : (
                          <div className="shrink-0 text-white">
                            <div className="h-6 w-6" />
                          </div>
                        )
                      }
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            );
          })
        }
      </div>
    </RadioGroup>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
