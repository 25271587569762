
export default function(handler) {
  const newHandler = async (...args) => {
    const handlerType = args[0] && args[1] ? 'api' : 'ssr';
    const req = handlerType === 'api' ? args[0] : args[0].req;
    const res = handlerType === 'api' ? args[1] : args[0].res;

    // if (req) {
    //   console.log(req);
    //   if (!req.url.startsWith('/c/')) {
    //     // theres a problem
    //     return res.status(500).json({
    //       message: 'Internal server error',
    //     });
    //   }

    //   // req.domain = req.url.substring('/c/'.length);
    // }

    return handler(...args);
  }

  return newHandler;
}
