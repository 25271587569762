import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import classnames from 'tailwindcss-classnames'

export default function FileDropZone(props) {
  const {
    onChange = () => {},
    value = [],
    multiple = false,
    acceptTypes,
    maxSize = 1024 * 1024 * 5, // 5 MB
    zoneWidth,
    zoneHeight,
    label,
  } = props

  const [files, setFiles] = useState([])
  const [errors, setErrors] = useState(value)

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles)
    onChange(acceptedFiles)
  }, [])

  useEffect(() => {
    setFiles(value)
  }, [value])

  const onDropRejected = useCallback((rejectedFiles) => {
    setErrors(rejectedFiles)
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: acceptTypes,
    maxSize,
    onDropRejected,
  })

  return (
    <div>
      {label && <p className="text-sm font-medium text-slate-700">{label}</p>}
      <div
        {...getRootProps()}
        className={classnames(
          'flex cursor-pointer items-center justify-center rounded-xl border-4 border-dashed border-slate-300 text-center text-base font-medium text-slate-600',
          {
            'bg-slate-200': isDragActive,
          }
        )}
        style={{
          width: zoneWidth || undefined,
          height: zoneHeight || undefined,
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop file{multiple ? 's' : ''} here...</p>
        ) : files.length === 0 ? (
          <p>Drop file{multiple ? 's' : ''} here or click to upload</p>
        ) : (
          <p>{files.length > 1 ? `${files.length} files` : files[0].name}</p>
        )}
      </div>
      {errors && errors.length > 0 && errors[0] && (
        <p className="text-sm text-red-700">
          Error with {errors[0].file.name}: {errors[0].errors[0].message}
        </p>
      )}
    </div>
  )
}
