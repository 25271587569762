import React, { useEffect } from 'react';
import useCommunity from '../../lib/data/useCommunity';
import community from '../../lib/middleware/community';
// import CircleLoader from './circleLoader';
import API from '../../lib/client';
import getEnv from '../../lib/getEnv';
import SnowballClient from '../../lib/snowballClient';
import { SnowballContext } from "./snowballWrapper";

export default function withSnowball(WrappedComponent, options = {}) {
  const HOC = (props) => {
    return (
      <SnowballContext.Consumer>
        {
          value => (
            <WrappedComponent
              {...props}
              snowball={value}
            />
          )
        }
      </SnowballContext.Consumer>
    );
  }

  HOC.getInitialProps = async (ctx) => {
    if (WrappedComponent.getInitialProps) {
      const result = await WrappedComponent.getInitialProps(ctx);
      return result;
    }
    return {};
  };

  return HOC;
}

// export function useWindowSize() {
//   // Initialize state with undefined width/height so server and client renders match
//   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
//   const [windowSize, setWindowSize] = useState({
//     width: undefined,
//     height: undefined,
//   });
//   useEffect(() => {
//     // Handler to call on window resize
//     function handleResize() {
//       // Set window width/height to state
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }
//     // Add event listener
//     window.addEventListener("resize", handleResize);
//     // Call handler right away so state gets updated with initial window size
//     handleResize();
//     // Remove event listener on cleanup
//     return () => window.removeEventListener("resize", handleResize);
//   }, []); // Empty array ensures that effect is only run on mount
//   return windowSize;
// };


