
import useSWR from 'swr';

export default function useCommunity(domain) {
  const {
    data: community,
    mutate: mutateCommunity,
    error: communityError,
  } = useSWR(`/api/proxy/community?domain=${domain}&findOne=1`, { refreshInterval: 3600000 });

  // const communityId = (communities||[])[0]?.id;

  // const { data: community, mutate: mutateCommunity, error: communityError } = useSWR(
  //   communityId ? `/api/proxy/community/${communityId}` : null,
  //   { refreshInterval: 120000 },
  // );

  // if (communities === undefined || community === undefined) {
  //   return {
  //     communityLoading: true,
  //     community: undefined,
  //     communityError: undefined,
  //     mutateCommunity,
  //   };
  // }

  return { community, mutateCommunity, communityError, communityLoading: false };
}
