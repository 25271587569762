
import React, { useEffect } from 'react';
import useCommunity from '../../lib/data/useCommunity';
import community from '../../lib/middleware/community';
// import CircleLoader from './circleLoader';
import API from '../../lib/client';
import getEnv from '../../lib/getEnv';
import SnowballClient from '../../lib/snowballClient';
import { useRouter } from 'next/router';

export default (WrappedComponent, options = {}) => {
  const HOC = (props) => {
    const router = useRouter();
    const {community, mutateCommunity, communityError, communityLoading} = useCommunity(router.query.domain);

    if (community) {
      community.needsClientLoaded = false;
    }

    return (
      <WrappedComponent
        {...props}
        community={{ ...props.community, ...community }}
        communityLoading={communityLoading}
        mutateCommunity={mutateCommunity}
        communityError={communityError}
        domain={props.domain || router.query.domain}
      />
    );
  }

  HOC.getInitialProps = community(
    async ({ req, res, query }) => {
      let result = {
        domain: query.domain,
        mcode: query.mcode,
      }

      if (req) {
        const referrer = req.headers['x-forwarded-host'];
        let baseUrl = 'https://api.withcomet.com/comet';
        if (!referrer || referrer.includes('lvh.me') || referrer.includes('localhost')) {
          // use local API
          baseUrl = 'http://localhost:9000/comet';
        } else if (referrer.includes('withcomet.dev')) {
          baseUrl = 'https://api.withcomet.dev/comet';
        }
        // const baseUrl = req
        const api = new API(baseUrl);

        const communities = await api.get(`/community?domain=${query.domain}`);
        if (communities.length === 1) {
          // perfect
          const community = {
            ...communities[0],
            needsClientLoaded: true,
          };
          result.community = community;
          req.community = community;
        }

        result.baseUrl = baseUrl;
      }

      if (WrappedComponent.getInitialProps) {
        result = {
          ...result,
          ...(await WrappedComponent.getInitialProps({req, res, query})),
        };
      }

      return result;
    }
  )

  return HOC;
}
