import classnames from "tailwindcss-classnames";

export default function CheckBox(props) {
  const {
    name,
    caption,
    value = false,
    onChange = (() => {}),
    disabled = false,
    align = 'start'
  } = props;

  return (
    <div className={classnames(
      `flex flex-row items-${align} gap-3 cursor-pointer`,
      {
        'opacity-50 pointer-events-none': disabled,
      },
    )}>
      <input
        id={name}
        name={name}
        type="checkbox"
        class="focus:ring-slate-500 h-5 w-5 text-slate-600 border-slate-300 rounded mt-0.5 cursor-pointer"
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <label for={name} class="text-gray-600 text-base font-medium cursor-pointer">{caption}</label>
    </div>
  );
}
