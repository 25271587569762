
import React from 'react';

export default (props) => {
  let url;
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }

  return (
    <>
      <meta property="og:title" content={props.title} />
      <meta property="og:type" content={props.type || 'website'} />
      <meta property="og:description" content={props.description || 'No description provided.'} />
      <meta property="og:image" content={props.image} />
      <meta property="og:url" content={props.url || url} />
      <meta name="twitter:card" content={props.twitterCard || 'summary_large_image'} />
    </>
  )
}
